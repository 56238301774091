<template>
  <BtnTemplate
    class="outlined"
    :class="{ selected, small }"
    @click="$emit('click', $event)"
  >
    <span class="btnText">
      <slot />
    </span>
  </BtnTemplate>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import BtnTemplate from './template.vue'

export default defineComponent({
  components: {
    BtnTemplate,
  },
  props: {
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {}
  },
})
</script>
